import {observer} from "mobx-react-lite";
import {QuestionComponent} from "../QuestionComponent";
import {ControlBoxComponent} from "../ControlBoxComponent/ControlBoxComponent";
import React, {useState} from "react";
import {gql, useMutation, useQuery} from "urql";
import AssessmentStore from "../assessments.store";
import _ from 'lodash'

const AssignmentQuery = gql`
    query($id:ID!){
        assessments{
            assessment:for(id:$id){
                id
                canOpen
                questions{
                    question{
                        id
                        header
                        type
                    }
                    currentAnswer{
                        currentAnswer
                    }
                }
            }
        }
    }`;

const updateQuestions = `
    mutation($id:ID, $answers:[AnsweredQuestion]) {
        assessments{
            submitQuestion(id:$id, questions:$answers){
                accepted
            }
        }

    }`

const submitAssessment = gql`mutation($submission:SubmitAssessment) {
    assessments{
        submitAssessment(submission: $submission){
            accepted
        }
    }
}`
export const Assessment = observer(({match, ...props}) => {
        const store = AssessmentStore();
        const {id} = match.params
        const [all] = useQuery({query: AssignmentQuery, variables: {id}})
        const {data, fetching, error, operation} = all;
        const [, executeCall] = useMutation(updateQuestions)
        const [, executeSubmitAssignment] = useMutation(submitAssessment)
        const callServer = () => {

            const updatedQuestions = Object.values((store.UpdateHistory || {}));
            if (updatedQuestions.length > 0)
                executeCall({id, questions: updatedQuestions})
                    .then((s) => {
                        console.log(s)
                    })
                    .catch(s => {
                        console.error(s);
                    })

        }
        const [callServerDebounce] = useState(() => _.debounce(callServer, 60000))
        if (!fetching && (store.FetchOperation !== operation.key)) {
            store.SupplyData(operation.key, data.assessments.assessment)
        }
        const loadingCode = () => <div>Loading...</div>
        if (fetching) {
            return loadingCode();
        }
        if (!data.assessments.assessment.canOpen) {
            return <div>Unable to open {id}</div>
        }
        if (store.currentQuestion == null) {
            return loadingCode()
        }
        if (error) {
            return <div>{{error}}</div>
        }
        return (
            <div>

                <QuestionComponent questionHeader={store.currentQuestion.header}
                                   questionType={store.currentQuestion.type}
                                   answer={store.currentAnswer}
                                   updateAnswer={(answer) => {
                                       store.updateAnswer(store.currentQuestion.id, answer)
                                       callServerDebounce()

                                   }}/>
                <ControlBoxComponent isFirst={store.isFirst} isLast={store.isLast} onNext={() => store.goNext()}
                                     onBack={() => store.goBack()}
                                     onSubmit={() => {
                                         executeSubmitAssignment({
                                             submission: {
                                                 id,
                                                 answers: (_.map(store.answers, (q, key) => {
                                                     return {questionId: key, answer: q, at: new Date().getTime()}
                                                 }))
                                             }
                                         }).then(console.log)
                                     }}/>

            </div>
        )
    }
)

Assessment.propTypes =
    {}
