import {FormControlLabel} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";


export const SingleAnswer = ({answer, updateQuestion, ...props}) => <FormControlLabel
    labelPlacement="top"
    control={<input value={answer || answer.is || ""}
                    onChange={({target}) => updateQuestion({
                        answer: {
                            is: target.value
                        }
                    })}/>}
    label="Answer to the Question"/>


SingleAnswer.propTypes = {
    answer: PropTypes.string.isRequired,
    updateQuestion: PropTypes.func.isRequired
}
