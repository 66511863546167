import React, {useContext, useState} from 'react'
import {observer} from "mobx-react-lite";
import PropTypes from "prop-types";
import {IconButton, List, ListItem, Switch} from '@material-ui/core'
import {Add, Delete, Remove} from '@material-ui/icons'
import {gql, Query} from "urql";
import QuestionsDomainContext from "../QuestionsDomainContext";
import {RunQuery} from "../../common";
import {AddNewQuestion} from "./AddNewQuestion";


const AllQuestionsQuery = gql`query{
    questions{
        id
        type
        header
    }
} `


const QuestionListItem = ({id, header}) =>{
    const [checked, updateChecked] = useState(true)
    const store = useContext(QuestionsDomainContext)

return         <ListItem button key={id}
              onClick={() => {
                  store.editQuestion(id)
              }}><Switch checked={checked} onClick={(event) => event.stopPropagation()} onChange={() => updateChecked(!checked)}/>{header}</ListItem>
}

const InnerQuestionList = ({data}) => {
    return <div>
        <AddNewQuestion/>
        <List>
            {data.questions.map(x => <QuestionListItem id={x.id} header={x.header}></QuestionListItem>)}
        </List>
    </div>
}

export const QuestionsList = observer(({...props}) => {
    return <RunQuery query={AllQuestionsQuery} loading={"Loading Questions.."}>
        {InnerQuestionList}
    </RunQuery>
});

QuestionsList.propTypes = {
    onSelected: PropTypes.func,
    questions: PropTypes.array.isRequired
}
