import {makeAutoObservable} from 'mobx'

export class QuestionsStore {
    constructor() {
        makeAutoObservable(this)
    }

    questions = {}
    selectedQuestion = null

    get SelectedQuestionId(){
        return this.selectedQuestion

    }

    AddQuestions(qs){
        if(qs instanceof Array)
        {
            qs.forEach(x => this.questions[x.id] = x )
        }
        else{
            this.questions[qs.id] = qs
        }
    }

    updateQuestion(question){
        this.questions[question.id] = question
    }

    editQuestion(id){
        this.selectedQuestion = id;
    }




}
const store = new QuestionsStore()
export default store;
