import {makeAutoObservable} from 'mobx'
// import {createClient} from 'urql'
/*
const client = createClient({
    url: 'http://localhost:3000/graphql',
});*/

class AssessmentsStore {
    questions = []
    answers = {}
    questionNumber = 0
    fetchOperation = 0
    updateHistory = {};

    constructor() {
        makeAutoObservable(this);

    }

    get currentQuestion() {
        if (this.questions.length === 0) {
            return null
        }
        return this.questions[this.questionNumber]
    }

    get UpdateHistory() {
        return this.updateHistory;
    }

    get FetchOperation() {
        return this.fetchOperation;
    }

    get isLast() {
        return this.questions.length - 1 === this.questionNumber
    };

    get isFirst() {
        return this.questionNumber === 0
    }

    get currentAnswer() {
        if (Object.keys(this.answers).length > 0) {
            let question = this.questions[this.questionNumber];
            if (question)
                return this.answers[question.id] || ''

        }
        return null;
    }

    goNext() {
        if (!this.isLast) {
            this.questionNumber++
        }
    }

    goBack() {
        if (!this.isFirst) {
            this.questionNumber--
        }
    }

    goToQuestion(number) {
        this.questionNumber = number;
    }

    SupplyData(lastOperation, updatedPayload) {
        if (!updatedPayload) {
            return;
        }
        const {questions} = updatedPayload
        this.fetchOperation = lastOperation;
        this.questions = []
        questions.forEach(x => {
                this.questions.push(x.question);
                if (x.currentAnswer && x.currentAnswer.currentAnswer) {

                    this.answers[x.question.id] = x.currentAnswer.currentAnswer
                }
            }
        )
    }

    updateAnswer(id, answer) {
        this.answers[id] = answer
        this.updateHistory[id] = {
            questionId: id,
            answer: answer,
            at: (new Date().getTime())
        }
    }

    clearHistory() {
        this.updateHistory = {}
    }


}

let store;
let storeSingleton = () => {
    if (store) {
        return store
    }
    store = new AssessmentsStore();
    return store
};
export default storeSingleton;
