import React from "react";
import {LongQuestionComponent} from "./LongQuestionComponent";
import {NumberQuestionComponent} from "./NumberQuestionComponent";
import {PropTypes} from "prop-types";


const displayAs = (type, answer, updateAnswer) => {
    switch (type) {
        case 'long':
            return <LongQuestionComponent answer={answer} updateAnswer={updateAnswer} />;
        case 'number':
            return <NumberQuestionComponent answer={answer} updateAnswer={updateAnswer}/>;
        default: return null;
    }
}

export const QuestionComponent = ({questionHeader, questionType, answer, updateAnswer, ...props}) => (
    <div>
        <h3>{questionHeader}</h3>
        {displayAs(questionType, answer, updateAnswer)}
    </div>)


QuestionComponent.propTypes = {
    questionHeader: PropTypes.string.isRequired,
    questionType: PropTypes.oneOf(['long', 'number']).isRequired,
    answer: PropTypes.string,
    updateAnswer: PropTypes.func
}
