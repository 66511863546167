import React from 'react'
import PropTypes from 'prop-types'
import {
    Select,
    ListItem,
    FormControlLabel,
    Box
} from "@material-ui/core";
import {SingleAnswer} from "./singleAnswer";
import {MultiAnswer} from "./multiAnswer";


export const AcceptableAnswers = ({answer, updateQuestion, markingType, ...props}) => {

    const SelectAnswerType = (type) => {
        switch (type) {
            case "single":
                return <SingleAnswer answer={answer[0]} updateQuestion={updateQuestion}/>
            case "one":
            case "each":
            case "all":
                return <MultiAnswer answers={answer} updateQuestion={(ans) => updateQuestion({
                    answer: {
                        markingType: type,
                        [type]: ans
                    }
                })}/>;
            default:
                return null;
        }
    }

    return <Box>
        <FormControlLabel
            labelPlacement="top"
            control={<Select value={markingType}>
                <ListItem value={"single"}>Single Answer</ListItem>
                <ListItem value={"one"}>One of</ListItem>
                <ListItem value={"each"}>Each of</ListItem>
                <ListItem value={"all"}>All over</ListItem>
            </Select>} label="Answer type"/>
        {SelectAnswerType(markingType)}
    </Box>;
}

AcceptableAnswers.propTypes = {
    answer: PropTypes.array.isRequired,
    markingType: PropTypes.string.isRequired,
    updateQuestion: PropTypes.func.isRequired
}
