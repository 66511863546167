import {FormControlLabel, Select, Switch} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {AcceptableAnswers} from "../AcceptableAnswerComponent/acceptableAnswers";
import React, {useContext, Fragment} from "react";
import QuestionsDomainContext from "../QuestionsDomainContext";


export const QuestionsForm = ({question}) => {
    const store = useContext(QuestionsDomainContext)
    return <Fragment>
        <FormControlLabel labelPlacement="top" control={<Switch checked={question.canAutoMark}
                                                                onChange={({target}) => store.updateQuestion({
                                                                    ...question,
                                                                    canAutoMark: target.checked
                                                                })}/>}
                          label="Auto Answer"/>
        <FormControlLabel labelPlacement="top" control={<input value={question.header}
                                                               onChange={({target}) => store.updateQuestion({
                                                                   ...question,
                                                                   header: target.value
                                                               })}/>}
                          label="Question Text"/>
        <FormControlLabel labelPlacement="top"
                          control={<Select value={question.type}
                                           onChange={({target}) => store.updateQuestion({
                                               ...question,
                                               type: target.value
                                           })}><MenuItem value="long">Large Text</MenuItem>
                              <MenuItem value="number">Number</MenuItem></Select>}
                          label="Question Type"/>
        {question.canAutoMark ? <AcceptableAnswers answer={question.answer.answer}
                                                   markingType={question.answer.type}
                                                   updateQuestion={(answer) => {
                                                       store.updateQuestion({...question, ...answer})
                                                   }}/> : null}
    </Fragment>;
}
