import React from 'react'
import {Button} from "@material-ui/core";
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite'



export const ControlBoxComponent = observer(({isLast, isFirst, onNext, onBack, onSubmit}) => <div>
    {!isFirst ? <Button onClick={onBack} variant="contained" color="secondary">Back</Button> : null}
    {isLast ? <Button onClick={onSubmit} color="primary" variant="contained">Submit</Button> :
        <Button onClick={onNext} color="primary" variant="contained">Next</Button>}
</div>)


ControlBoxComponent.propTypes = {
    isLast: PropTypes.bool,
    isFirst: PropTypes.bool,
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    onSubmit: PropTypes.func
}
ControlBoxComponent.defaultProps = {
    isLast: false,
    isFirst: false,
    onNext: () => {
    },
    onBack: () => {
    },
    onSubmit: () => {
    }
}
