import React from 'react'
import {observer} from 'mobx-react-lite'
import PropTypes from 'prop-types'
import {Box} from "@material-ui/core";
import {gql, Query} from "urql";
import {QuestionsForm} from "./QuestionForm";


const fetchQuestionDetails = gql`query($id:ID!) {
    question(id:$id){
        header
        id
        type
        canAutoMark
        answer{
            answer
            type
        }
    }
}`

export const QuestionUpdate = observer(({selectedQuestionId}) => {
    console.log(selectedQuestionId)
    return (
            <Query query={fetchQuestionDetails} variables={{id: selectedQuestionId}}>
                {({fetching, data, error}) =>
                    fetching ? "Loading...." : error? <div>{error.graphQLErrors.map(x => x.message)}{error.toString()}{error.request} </div>: <Box>
                        <QuestionsForm question={data.question}/></Box>}</Query>)
    }
)
QuestionUpdate.propTypes =
    {
        selectedQuestionId: PropTypes.string.isRequired
    }
