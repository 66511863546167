import React from 'react'
import './App.css';
import {BrowserRouter as Router, Link, Switch, Route} from 'react-router-dom'
import { createClient, Provider } from 'urql';

import {Assessment} from "./assessments/PageComponent";
import {AppBar, Toolbar, IconButton, Button, Box} from "@material-ui/core";
import {Menu as MenuIcon} from '@material-ui/icons'

import {Questions} from "./questions";


const client = createClient({
    url: 'http://localhost:5001/assessments-5ee27/us-central1/graphql',
});

function App() {



    return (
        <Provider value={client}>
        <Router>
            <AppBar>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu">
                        <MenuIcon/>
                    </IconButton>
                    <Link to="/assessments/rCYDNzKhpUGKRGR4vWQJ"><Button>Assessments</Button></Link>
                    <Link to="/questions"><Button>Questions</Button></Link>
                    <Link to="/marking"><Button>Marking</Button></Link>
                    <Link to="/dashboard"><Button>Dashboard</Button></Link>
                    <Link to="/assessment"><Button>Nowhere</Button></Link>

                </Toolbar>
            </AppBar>
            <Box style={{marginTop: 80}} height="100%">
                <Switch>
                    <Route exact path="/assessments/:id" component={Assessment}/>
                    <Route exact path="/questions">
                        <Questions/>
                    </Route>
                    <Route>
                        <div>Not Found</div>
                    </Route>
                </Switch>
            </Box>
        </Router></Provider>
    );
}

export default App;
