import React from "react";
import PropTypes from 'prop-types';
import {TextareaAutosize} from "@material-ui/core";

export const LongQuestionComponent = ({answer, updateAnswer,...props}) => <div>
    <TextareaAutosize onChange={({currentTarget}) => {updateAnswer(currentTarget.value)}} rowsMin={15} cols={150} value={answer}></TextareaAutosize>
</div>

LongQuestionComponent.propTypes= {
    answer: PropTypes.string.isRequired,
    updateAnswer: PropTypes.string.isRequired
}
