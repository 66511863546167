import {observer} from "mobx-react-lite";
import {Box} from "@material-ui/core";
import React from "react";
import {QuestionsList} from "./QuestionsListComonent";
import {QuestionUpdate} from "./QuestionUpdateComponent";
import QuestionsDomainContext from "./QuestionsDomainContext";
import store from "./questions.store";


export const ContextAndLayout = observer(({...props}) => {

    return <QuestionsDomainContext.Provider value={store}>
        <Box display="flex" height="calc(100vh - 85px)" bgcolor="grey.300">
            <QuestionsList />
            {store.SelectedQuestionId? <QuestionUpdate selectedQuestionId={store.SelectedQuestionId}/>:null}
        </Box>
    </QuestionsDomainContext.Provider>
});

ContextAndLayout.propTypes = {
}
