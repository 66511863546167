import {Box, FormLabel} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

export const MultiAnswer = ({answers, updateQuestion, ...props}) =>
    [<FormLabel>Answer to the Question</FormLabel>,
        <Box>
            {answers.map(a => <input value={a || ""}
                                     onChange={({target}) => updateQuestion(target.value)}/>)}</Box>
    ]

MultiAnswer.propTypes = {
    answers: PropTypes.array.isRequired,
    updateQuestion: PropTypes.object.isRequired
}
