import React from 'react';
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";

export const NumberQuestionComponent = ({answer, updateAnswer, ...props}) => <div>
    <TextField onChange={({currentTarget}) => {updateAnswer(currentTarget.value)}} variant="filled" type="number" value={answer} />
</div>


NumberQuestionComponent.propTypes= {
    answer: PropTypes.string.isRequired,
    updateAnswer: PropTypes.func.isRequired
}
